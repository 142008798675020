import React, { Component } from "react";
import arrow from './../../images/arrow-left.png' 

export default class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex   : 1,
            maxShowItems  : props.maxShowItems, //visible items on page
            visibleNumbers: 3,
            listLenght    : Math.ceil(props.newsLength / props.maxShowItems),
        }
    }
    changePage(index) {
        this.setState({ activeIndex: index })
        this.props.changePage(index);
        this.scrollPage();
    }
    scrollPage(){
        let el = document.querySelector('.filters');
        let topPos = el.offsetTop;
        document.documentElement.scrollTop = topPos + 300;
    }
    render() { 
        const ListLenght = Math.ceil(this.props.newsLength / this.state.maxShowItems);
        const List = () => {
            let tempArray = [];
            for (let i = 1; i <= ListLenght; i++) {
                tempArray = [...tempArray, i];
            }
            return tempArray.map((item) => {

                if( item < ListLenght && item == this.state.activeIndex - 1 || item == this.state.activeIndex || item == this.state.activeIndex + 1 || item == this.state.activeIndex + 2 ) {
                    if ( item != ListLenght ) {
                        return <li className={(item == this.state.activeIndex ? 'active':'')} onClick={this.changePage.bind(this, item)}>{ item }</li>
                    }
                    if (item == ListLenght && ListLenght <= this.state.visibleNumbers) {
                        return <li className={(item == this.state.activeIndex ? 'active':'')} onClick={this.changePage.bind(this, item)}>{ item }</li>
                    }
                }


                if( this.state.activeIndex < ListLenght && ListLenght > this.state.visibleNumbers) {
                    return (
                        <>
                            { item == 1 || item == ListLenght && 
                                <li className="dots">...</li>
                            }
                            <li className={(item == this.state.activeIndex ? 'active':'')} onClick={this.changePage.bind(this, item)}>{ item }</li>
                        </>
                    )
                }

                if( this.state.activeIndex == ListLenght && item == 1) {
                    return (
                        <>
                            <li className={(item == this.state.activeIndex ? 'active':'')} onClick={this.changePage.bind(this, item)}>{ item }</li>
                            <li className="dots">...</li>
                        </>
                    )
                }
                if (ListLenght == this.state.activeIndex) {
                    return <li className={(item == this.state.activeIndex ? 'active':'')} onClick={this.changePage.bind(this, item)}>{ item }</li>
                }

            });

        }  

        return (
            <section className="pagination-wrap">
                <div className="container">
                    <div className="pagination">
                        { this.state.activeIndex > 1 && 
                            <div className="arrow prev" onClick={ () => this.changePage( this.state.activeIndex - 1 ) }>
                                <img src={ arrow } />
                            </div>
                        }
                        <ul>
                            <List />
                        </ul>
                        { this.state.activeIndex < this.state.listLenght && 
                            <div className="arrow next" onClick={ () => this.changePage( this.state.activeIndex + 1 ) } >
                                <img src={ arrow } />
                            </div> 
                        }
                    </div>
                </div>
            </section>
        );
    }
}
