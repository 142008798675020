import React from "react";
import { Link } from 'gatsby';

export default (props) => (
    <div class=" col-xl-4 col-lg-6">
        <div class="portfolio__box two">
            { props.data.acf.image && 
                <img src={ props.data.acf.image.localFile.childImageSharp.fixed.src } alt={ props.data.title } />
            }
            <div class="portfolio__content">
                { props.data.collections.map(item => {
                    return props.collections.map(el => {
                        if(el.node.wordpress_id == item) {
                            return (
                                <h3>{ el.node.name }</h3>
                            )
                        }
                    })
                }) }
                <h5>{ props.data.acf.city }</h5>
            </div>
            <Link to={ "portfolio/" + props.data.slug } class="btn">Czytaj więcej</Link>
        </div>
    </div>
);