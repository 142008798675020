import React, { Component } from "react";
import { Link } from 'gatsby';
import arrow from './../../images/arrow-bottom.png';

export default class NewsFilters extends Component {
    constructor(props){
        super(props);
    }
    handleCollection(event) {
        this.props.changeCollection(event.target.value);
    }
    handleColor(event) {
        this.props.changeColor(event.target.value);
    }
    handleRoom(event) {
        this.props.changeRoom(event.target.value);
    }
    search() {
        this.props.search();
    }
    render() {
        return(
            <div className="newsletter filters">
                <div className="container">
                    <form action="#">
                        <div className="inputs">
                            <label for="">
                                <span>
                                    <img src={arrow} alt="Rozwiń" />
                                </span>
                                <select onChange={ this.handleRoom.bind(this) }>
                                    <option value="" selected>Pomieszczenie</option>
                                    { this.props.rooms.map(item => (
                                        <option value={ item.node.wordpress_id }>{ item.node.name }</option>
                                    ))}
                                </select>
                            </label>
                            <label for="">
                                <span>
                                    <img src={arrow} alt="Rozwiń" />
                                </span>
                                <select onChange={ this.handleCollection.bind(this) }>
                                    <option value="" selected>Kolekcja</option>
                                    { this.props.collections.map(item => (
                                        <option value={ item.node.wordpress_id }>{ item.node.name }</option>
                                    ))}
                                </select>
                            </label>
                            <label for="">
                                <span>
                                    <img src={arrow} alt="Rozwiń" />
                                </span>
                                <select onChange={ this.handleColor.bind(this) }>
                                    <option value="" selected>Kolor</option>
                                    { this.props.colors.map(item => (
                                        <option value={ item.node.wordpress_id }>{ item.node.name }</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="button">
                            <button type="button" onClick={ this.search.bind(this) }> Filtruj </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
