import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner'
import SimpleText from '../components/simple-text/SimpleText'
import RealizationsFilters from '../components/realizations-filters/RealizationsFilters'
import PortfolioItem from '../components/portfolio/PortfolioItem'
import Pagination from '../components/pagination/Pagination'
import SEO from '../components/seo'

class NewsTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      filteredItems: [],
      room: '',
      collection: '',
      color: '',
      visibleItems: 12,
      actualPage: 1,
    }
  }
  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-120px'
  }
  changeRoom(value) {
    this.setState({ room: value })
  }
  changeCollection(value) {
    this.setState({ collection: value })
  }
  changeColor(value) {
    this.setState({ color: value })
  }
  setPostsToState() {
    this.setState({
      items: this.props.data.allWordpressWpPortfolio.edges,
      filteredItems: this.props.data.allWordpressWpPortfolio.edges,
    })
  }

  async search() {
    let tempNews = this.state.items
    if (
      this.state.collection == '' &&
      this.state.room == '' &&
      this.state.color == ''
    ) {
      await this.setPostsToState()
    } else {
      await this.setState({ filteredItems: [] })
      await tempNews
        .filter(item =>
          this.state.room != '' ? item.node.rooms[0] == this.state.room : item
        )
        .filter(item =>
          this.state.collection != ''
            ? item.node.collections[0] == this.state.collection
            : item
        )
        .filter(item =>
          this.state.color != ''
            ? item.node.colors.indexOf(parseInt(this.state.color)) > -1
            : item
        )
        .map(item => {
          this.setState({ filteredItems: [...this.state.filteredItems, item] })
        })
    }
  }
  componentDidMount() {
    this.setFooterMargin()
    this.setPostsToState()
  }
  changePage(index) {
    this.setState({ actualPage: index })
  }
  render() {
    const currentPage = this.props.data.wordpressPage

    const Items = data => {
      let n = 0
      let maxItems = data.data.visibleItems
      let actualPage = data.data.actualPage
      let items = data.data.filteredItems.map((item, index) => {
        if (
          n < maxItems &&
          index <= Math.round(maxItems * actualPage - 1) &&
          index >= Math.round(maxItems * actualPage - 1 - maxItems + 1)
        ) {
          n++
          return (
            <PortfolioItem
              key={index}
              data={item.node}
              collections={this.props.data.allWordpressWpCollections.edges}
            />
          )
        }
      })
      return items
    }
    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up">
          <SimpleText text={currentPage.content} />
          <RealizationsFilters
            collections={this.props.data.allWordpressWpCollections.edges}
            rooms={this.props.data.allWordpressWpRooms.edges}
            colors={this.props.data.allWordpressWpColors.edges}
            changeCollection={this.changeCollection.bind(this)}
            changeRoom={this.changeRoom.bind(this)}
            changeColor={this.changeColor.bind(this)}
            search={this.search.bind(this)}
          />
          <div class="container portfolio realizations">
            <div class="row">
              <Items data={this.state} />
            </div>
          </div>
          {this.state.filteredItems.length > this.state.visibleItems && (
            <Pagination
              newsLength={this.state.filteredItems.length}
              changePage={this.changePage.bind(this)}
              maxShowItems={this.state.visibleItems}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default NewsTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
    }
    allWordpressWpPortfolio(filter: { status: { eq: "publish" } }) {
      edges {
        node {
          slug
          collections
          rooms
          colors
          acf {
            city
            title
            image {
              localFile {
                childImageSharp {
                  fixed(width: 480, height: 505, quality: 95) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpRooms {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpColors {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpCollections {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
