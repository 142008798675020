import React from 'react';

export default (props) => (
    <div className="container about-intro">
        <div className="row">
            <div className="col-12" dangerouslySetInnerHTML={{ __html: props.text }} ></div>
            <div class="col-12">
                <hr />
            </div>
        </div>
    </div>
);